<template>
  <the-header></the-header>
  <router-view></router-view>
  <scroll-to-top></scroll-to-top>
  <the-footer></the-footer>
</template>
<script>
import TheHeader from "./components/global/TheHeader.vue";
import TheFooter from "./components/global/TheFooter.vue";
import AOS from "aos";
import ScrollToTop from "./components/Helper/ScrollToTop.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
    ScrollToTop,
  },

  mounted() {
    AOS.init();
  },
};
</script>
<style >

@font-face {
  font-family: "Dance";
  src: local("Dance"),
  url(./assets/fonts/Dance.ttf) format("truetype");
}

@keyframes top {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 450px) {
  .vue-scroll-up {
    bottom: 10px;
    transform: translateX(5px) scale(0.7);
  }
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Dance", sans-serif;
  background: #4834d4;
  overflow-x: hidden;
  background: linear-gradient(to right, #09ffc7, #eaa664);
}
.darker-blue {
  background: linear-gradient(
    180deg,
    #240044 0,
    #0f0240 25%,
    #400959 40%,
    #0f0240 65%,
    #0f0240
  );
}

.orange {
  background: linear-gradient(to right, #08b78f, #d98331);
}

.white {
  background: ghostwhite;
}

.grey {
  background: #a9a1a4;
}

.disco {
  background: #0c1142;
}

.darker {
  background: deeppink;
  background: linear-gradient(to right, #09ffc7, #eaa664);
}
.darker2 {
  background: #090134;
}
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Dance", sans-serif;
  color: #fff;
  line-height: 1.4;
  font-weight: 500;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  outline: none;
}

li {
  list-style: none;
}

p {
  line-height: 1.9;
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
.section-header {
  position: relative;
  margin-bottom: 50px;
}
.section-header .desc {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}
.header-area .light.classy-nav-container a {
  color: #fff;
}

.header-area.sticky .classy-navbar-toggler .navbarToggler span {
  background: #333 !important;
}

.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}
.mr-5p {
  margin-right: 5px;
}
.mr-10p {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-100 {
  margin-right: 100px;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
@media (max-width: 767px) {
  .no-padding-right {
    padding-right: 15px !important;
  }
  .no-padding-left {
    padding-left: 15px !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-30 {
  margin-left: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-100 {
  margin-left: 100px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-60 {
  padding-top: 60px;
}
@media (max-width: 992px) {
  .double-bg {
    background-size: cover !important;
  }
  .mt-s {
    margin-top: 30px !important;
  }
  .padding-t-md-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .mt-x {
    margin-top: 30px !important;
  }

  .main-ilustration-5 {
    height: 65vh !important;
  }
}
@media (max-width: 480px) {
  .welcome_area.ico {
    height: 100vh !important;
  }
  .main-ilustration-5 {
    display: none;
  }
  .integration-text {
    font-size: 12px;
  }
  .integration-icon .badge {
    display: none;
  }
  .spons {
    border-top: 1px solid #eee;
  }
  .spons img {
    width: 80%;
  }
}
.section-padding-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section-padding-100-70 {
  padding-top: 100px;
  padding-bottom: 70px;
}
.section-padding-0-70 {
  padding-top: 0px;
  padding-bottom: 70px;
}
.section-padding-100-85 {
  padding-top: 100px;
  padding-bottom: 85px;
}
.section-padding-0-100 {
  padding-top: 0px;
  padding-bottom: 100px;
}
.b-text {
  color: #272e3a !important;
}
.w-text {
  color: #fff !important;
}
.g-text {
  color: #d8d0d0 !important;
}
.gray-text {
  color: #949292 !important;
}
.bold {
  font-weight: 600 !important;
}
.p-text {
  color: blueviolet !important;
}
.normal {
  font-weight: 500;
}
.width-100 {
  width: 100%;
}

.magic-colors {
  background: linear-gradient(to right, #F0F 20%, #FF0 40%, #FF0 60%, #0FF 80%);
  background-size: 200% auto;

  color: #000;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% bottom;
  }
}

</style>
