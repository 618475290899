<template>
<!--  <div class="sun"></div>-->
  <div class="grid-wrapper">
    <div class="sun"></div>
    <div class="grid" style="--grid-color:rgba(23,203,161,0.7);--grid-size:10px;--grid-blur:1px;"></div>
    <div class="dancing-dogs" id="ruppert"></div>
    <div class="dancing-dogs" id="herbert"></div>
    <div class="dancing-dogs" id="whisky"></div>
    <div class="dancing-dogs" id="vodka"></div>

    <b-section class="hero-section moving section-padding" id="home">
      <div class="moving-bg"></div>
      <!-- Hero Content -->
      <div class="hero-section-content">
        <b-container>
          <div class="row align-items-center">
            <!-- Welcome Content -->
            <b-col col="12" lg="10" md="12">
              <div class="welcome-content" id="danse-ser">
                <div class="promo-section">
                  <h3 class="special-head">
                    U should danse ser!
                  </h3>
                </div>
                <h1>
                  Free ur dancin movez wiz
                  <span class="magic-colors">DancingDoge</span>
                </h1>
                <p class="w-text">
                  Much dance, much moves, much woooow!
                  Come and dance with us under ze moon, til we rich it!
                </p>
                <!--              <div class="dream-btn-group">
                                <base-button mode="btn more-btn me-3">how to buy</base-button>
                              </div>-->
              </div>
            </b-col>
            <b-col lg="6"></b-col>
          </div>
        </b-container>
      </div>
    </b-section>
  </div>

  <b-section class="hero-section moving section-padding" id="what-dancingDoge">
    <div class="hero-section-content">
      <b-container>
        <div class="row align-items-center justify-content-center">
          <!-- Welcome Content -->
          <b-col col="8" lg="6" md="12">
            <div class="welcome-content">
              <div class="promo-section">
                <h3 class="special-head">
                  What's <span class="magic-colors">DancingDoge</span> ?
                </h3>
              </div>
              <h1>
                <span class="magic-colors">DancingDoge</span>
                is good!
              </h1>
              <p class="w-text">
                DancingDoge token main goal is to digitalize assets for rebalancing the blablabla... who cares? Let's
                drink, dance and wait for something cool to happen!
                If ur really eager to do something interesting, go to the club, enjoy ur life, and try to count all ze
                intentional typos.
                Spoiler: zere's a lot!
              </p>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="static-bg">
              <video autoplay muted loop id="crazy-party">
                <source src="@/assets/videos/crazy-partying.mp4"/>
              </video>
            </div>
          </b-col>
        </div>
      </b-container>
    </div>
  </b-section>

  <section>
    <div id="bars">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </section>

  <section class="section-padding-0-100" style="margin-top: 120px" id="ecosystem-info">
    <div class="container">

      <div class="section-heading text-center">

        <div class="justify-content-center fadeInUp" data-wow-delay="0.2s">
          <span style="font-size: 36px;" class="ecosystem"><span
              class="magic-colors">DancingDoge</span> ecosystem</span>
        </div>
      </div>


      <div class="row space-between">
        <div class="col-12 col-md-4 col-lg-4">

          <div style="margin-bottom: 0px; visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
               class="service_single_content collection-item wow fadeInUp" data-wow-delay="0.3s">
            <div class="collection_icon" style="text-align: center; padding-top: 1rem;">

              <div class="ecosystem-image liquidity"></div>
            </div>
            <div class="collection_info" style="text-align: center;">
              <h4><span class="magic-colors">DancingDoge</span> tokens</h4>
              <p>10% - <span class="w-text">9 638 225</span> for liquidity - beer, vodka, gin, as long as it is both
                liquid and drinking</p>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <div style="margin-bottom: 0; visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"
               class="service_single_content collection-item wow fadeInUp" data-wow-delay="0.4s">
            <div class="collection_icon" style="text-align: center; padding-top: 1rem;">
              <div class="ecosystem-image airdrop" style="text-align: center;"></div>
            </div>
            <div class="collection_info" style="text-align: center">
              <h4>Airdrop</h4>
              <p>30% - <span class="w-text">28 914 676</span> will be dropped soon (kinda like ze bass!)</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="obama-drop">
      <div class="obama-drop-content"></div>
    </div>
  </section>
</template>

<style scoped src="./landing-home.css"></style>
<style>
#bars {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 12rem;
  width: 100%;
  justify-content: center;
}

.bar {
  background: linear-gradient(to bottom, #091ea9 0, #efbe2b 24%, #b160dc 45%, #d53567 60%, #f0c3d9 65%, #0c1142 100%) fixed;
  height: 3px;
  position: relative;
  bottom: 1px;
  width: 10px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: .35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 10rem;
  }
}

.bar:nth-child(1) {
  left: 0;
  animation-duration: 874ms;
}

.bar:nth-child(2) {
  left: 15px;
  animation-duration: 833ms;
}

.bar:nth-child(3) {
  left: 30px;
  animation-duration: 807ms;
}

.bar:nth-child(4) {
  left: 45px;
  animation-duration: 858ms;
}

.bar:nth-child(5) {
  left: 60px;
  animation-duration: 800ms;
}

.bar:nth-child(6) {
  left: 75px;
  animation-duration: 827ms;
}

.bar:nth-child(7) {
  left: 90px;
  animation-duration: 841ms;
}

.bar:nth-child(8) {
  left: 105px;
  animation-duration: 819ms;
}

.bar:nth-child(9) {
  left: 120px;
  animation-duration: 887ms;
}

.bar:nth-child(10) {
  left: 135px;
  animation-duration: 842ms;
}
</style>

<script>
import BSection from '@/components/Helper/BSection'

export default {
  components: { BSection },
}
</script>
