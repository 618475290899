<template>
  <!-- Spinner -->

  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <!-- Welcome Area Start  -->
  <landing-home></landing-home>
  <!-- Live Auctions Section  -->
  <b-section class="features section-padding-0-70">
    <live-section
      mainHeading="Live Auctions 🔥"
      subHeading="Live auctions
"
    ></live-section>
  </b-section>
</template>

<script>
import LandingHome from "../components/local/Sections/home/LandingHome.vue";
export default {
  components: {
    LandingHome,
  },
  data() {
    return {
      isLoading: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
};
</script>
<style scoped>

.integration-link > * {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
}

.welcome-content h2 {
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 20px;
}
.welcome-content h1 {
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 20px;
}

.welcome-content p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
}
</style>
